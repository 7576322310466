import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import ReactPlayer from 'react-player';
import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Tools, Tips, & Tricks to Finding a Manufacturer`}</h1>
    <p><strong parentName="p">{`Day 12`}</strong></p>
    <p>{`If you missed yesterday’s update - stop right here. Don’t take another step
until you watch the video
(`}<a parentName="p" {...{
        "href": "https://www.loom.com/share/9821fc28441c4d0ea19ef6c2b1111dc7"
      }}>{`click here to watch it`}</a>{`)
I posted yesterday of using a set of spy-tools to figure out if there’s any
demand for the product I’m thinking about.`}</p>
    <p>{`Btw - At the bottom of every email, I ask you to rate the email - yesterday was
the first time we ever got 100% “excellent” ratings!`}</p>
    <p>{`Ok back to today’s update.`}</p>
    <p>{`The day started off with a phone call with Leland, a buddy I met thru the
podcast who runs Bumpboxes (awesome subscription box company for moms)`}</p>
    <p><strong parentName="p">{`I asked him what ecom products/categories have caught his eye. Here’s my
notes:`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Likes sunglasses`}</strong>{` as a product. High margin, cheap to ship, 70% of the
market controlled by Luxottica.`}</li>
      <li parentName="ul"><strong parentName="li">{`Instrument rentals`}</strong>{` - his daughter does band in school, so he had to go
rent a violin locally. Why can’t this be done easily online? Current companies
doing this seem shitty.`}</li>
      <li parentName="ul"><strong parentName="li">{`Crazy story`}</strong>{` - there’s a site called DLD-vip.com that sells webinar seats
(`}{`$`}{`25 a pop) and you get a chance to win a high priced gun at the end… and by
the end of the webinar, some people just buy bc they like the sales pitch.
Hilarious & genius model.`}</li>
      <li parentName="ul"><strong parentName="li">{`Good tip on shipping`}</strong>{` - you can partner with people who already have big
fedex accounts and piggyback on their account to get favorable rates`}</li>
      <li parentName="ul"><strong parentName="li">{`Fun kids product idea`}</strong>{`: `}<a parentName="li" {...{
          "href": "https://happynappers.com/"
        }}>{`Happy Nappers`}</a></li>
    </ul>
    <p><a parentName="p" {...{
        "href": "https://soundcloud.com/shaan-puri-429311607/leland/s-FAG3a2uSqQV"
      }}>{`Here’s an audio snippet of our conversation`}</a>{`
where he talks about how he would thinking about launching that sunglasses
brand...`}</p>
    <p><strong parentName="p">{`OK now for getting quotes/sources for my product. I can’t start selling until
I’ve felt/seen the product and know I’m selling something good.`}</strong></p>
    <p>{`When i launched my last ecommerce business, I really had no idea how to go about
an idea to sourcing it. It took months of trial and error to get it right.`}</p>
    <p>{`I made all of the mistakes - not knowing how to find a factory, how to figure
out what materials to use, colors, etc. Not knowing how international shipping
works. Not knowing what a trading company is.. it was all way over my head.`}</p>
    <p>{`Even though I figured a lot of that out - I’ve learned it’s much easier to work
with a sourcing company. Thank jesus I have
`}<a parentName="p" {...{
        "href": "https://twitter.com/naterez94"
      }}>{`Nathan`}</a>{` at Sourcify to make this part easy.`}</p>
    <p><strong parentName="p">{`Now, you get more multimedia!`}</strong></p>
    <p><strong parentName="p">{`Nathan recorded a 20-minute video to show the step-by-step process of sourcing
a manufacturer:`}</strong>{`
`}<a parentName="p" {...{
        "href": "https://vimeo.com/459215758/e0e94d181c"
      }}>{`Click here to watch it`}</a></p>
    <ReactPlayer url='https://vimeo.com/459215758/e0e94d181c' mdxType="ReactPlayer" />
    <p><strong parentName="p">{`If you want us to lay out all the tools we used to generate demand from
yesterday and what we used today to find a manufacturer in a pdf, send us a
reply here and let us know!`}</strong></p>
    <p><strong parentName="p">{`From Sourcing --> Quote`}</strong></p>
    <p>{`To get a quote from a few factories for both a high-quality sample and a
potential big order, we’ve gotta specify what we want.`}</p>
    <p><strong parentName="p">{`What exactly does that mean? It means we need to know all this stuff:`}</strong></p>
    <ul>
      <li parentName="ul">{`Material composition`}</li>
      <li parentName="ul">{`Height x width x length measurements`}</li>
      <li parentName="ul">{`Weight`}</li>
      <li parentName="ul">{`Colors`}</li>
      <li parentName="ul">{`Example photos etc..`}</li>
    </ul>
    <p>{`One way to come up with these answers is just by digging around and using
competitive products. You can check their labels, their FAQs, buy their product
and measure it yourself, etc. Another way is just to do some good ole' guessing`}</p>
    <p>{`Time to follow that advice myself and build our spec sheet and get it off to
Nathan…`}</p>
    <p><strong parentName="p">{`Cue 6 hours later on the movie screen w one of those James Bond songs`}</strong></p>
    <p>{`Nathan hits us already with a full quote on one of our ideas - the car bed…`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "908px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "27.249999999999996%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAFABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAEDBf/EABYBAQEBAAAAAAAAAAAAAAAAAAIEBf/aAAwDAQACEAMQAAAB2qzOciJf/8QAFxAAAwEAAAAAAAAAAAAAAAAAAQIQIf/aAAgBAQABBQIrs//EABURAQEAAAAAAAAAAAAAAAAAAAIQ/9oACAEDAQE/ATP/xAAXEQADAQAAAAAAAAAAAAAAAAABBBAy/9oACAECAQE/AXNCf//EABcQAAMBAAAAAAAAAAAAAAAAABAREkH/2gAIAQEABj8CrUf/xAAZEAADAAMAAAAAAAAAAAAAAAAAARExQWH/2gAIAQEAAT8hXsiMk6I//9oADAMBAAIAAwAAABBz7//EABURAQEAAAAAAAAAAAAAAAAAABAx/9oACAEDAQE/EIP/xAAWEQADAAAAAAAAAAAAAAAAAAABEJH/2gAIAQIBAT8QKC//xAAaEAEAAgMBAAAAAAAAAAAAAAABABEhMUFx/9oACAEBAAE/EGtt4eK8giBjc//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Car Bed",
          "title": "Car Bed",
          "src": "/static/6c776967147c53b92b932a4e5e491764/1e958/car-bed.jpg",
          "srcSet": ["/static/6c776967147c53b92b932a4e5e491764/a6407/car-bed.jpg 400w", "/static/6c776967147c53b92b932a4e5e491764/a24e6/car-bed.jpg 800w", "/static/6c776967147c53b92b932a4e5e491764/1e958/car-bed.jpg 908w"],
          "sizes": "(max-width: 908px) 100vw, 908px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><strong parentName="p">{`Based on my past experience with ecomm stores, a few big things pop out at me
to look for here:`}</strong></p>
    <ul>
      <li parentName="ul">{`How long it takes to get a sample - in this case, 25 - 35 days (that means if
version 1 is a 6/10, we won’t have a shot at getting a 10/10 until atleast the
end of November)`}</li>
      <li parentName="ul">{`Identifying the things we can do to ensure a higher quality sample`}</li>
      <li parentName="ul">{`Being as specific as possible with the direction (especially with language
barriers)`}</li>
    </ul>
    <p>{`Going back and forth with Nathan now, we should be able to get 3 different
product samples all ordered by eod tomorrow...and hit our A+ week 3 weeks in a
row!`}</p>
    <p>{`Have a good friday,`}<br parentName="p"></br>{`
`}{`Shaan`}</p>
    <p>{`P.S.
`}<a parentName="p" {...{
        "href": "https://twitter.com/robinroberts/status/1306207455584555014?s=21"
      }}>{`this is a must-read story on twitter`}</a></p>
    <Links links={[{
      title: LinkLabel.Multimedia,
      links: [{
        url: 'https://www.loom.com/share/9821fc28441c4d0ea19ef6c2b1111dc7',
        label: 'Ecommerce - Validating Demand Loom'
      }, {
        url: 'https://soundcloud.com/shaan-puri-429311607/leland/s-FAG3a2uSqQV',
        label: 'Chat with Leland from Bump Boxes'
      }, {
        url: 'https://vimeo.com/459215758/e0e94d181c',
        label: "Nathan's step-by-step process of sourcing from a manufacturer"
      }]
    }, {
      title: LinkLabel.Web,
      links: [{
        url: 'https://happynappers.com/',
        label: 'Happy Nappers Kids Product'
      }, {
        url: 'https://twitter.com/robinroberts/status/1306207455584555014?s=21',
        label: 'Special update from WNBA star Maya Moore and Jonathan Irons'
      }]
    }]} mdxType="Links" />
    <PostFooter prevButton={{
      text: postMap.validating_demand_for_product_ideas.label,
      slug: slugify(postMap.validating_demand_for_product_ideas.slug)
    }} nextButton={{
      text: postMap.product_samples_are_on_the_way.label,
      slug: slugify(postMap.product_samples_are_on_the_way.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      